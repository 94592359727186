import type { CurrencyCode, ViewItemEvent } from '@faststore/sdk'
import { sendAnalyticsEvent } from '@faststore/sdk'
import type { ProductDetailsFragment_ProductFragment } from '@generated/graphql'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import { useCmsCollectionFlags } from 'src/components/hooks/useCmsGlobalFlags'
import { useGetMulticurrencyPrices } from 'src/components/hooks/useGetMulticurrencyPrices'
import { useGetSellerInfos } from 'src/components/hooks/useGetSellerInfos'
import { useShippingSimulation } from 'src/components/hooks/useShippingSimulation'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'
import OutOfStock from 'src/components/product/OutOfStock'
import PriceSkeleton from 'src/components/skeletons/PriceSkeleton/PriceSkeleton'
import { ShippingBadge } from 'src/components/ui/Badge'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import BuyButton from 'src/components/ui/BuyButton'
import { ShopIcon } from 'src/components/ui/Icons/ShopIcon'
import { ImageGallery } from 'src/components/ui/ImageGallery'
import ProductTitle from 'src/components/ui/ProductTitle'
import QuantitySelector from 'src/components/ui/QuantitySelector'
import CustomSelectors from 'src/components/ui/SkuSelector/CustomSelector'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import { cartStore, useCart } from 'src/sdk/cart'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'
import { useDiscountPercent } from 'src/sdk/product/useDiscountPercent'
import { useProduct } from 'src/sdk/product/useProduct'
import { useSession } from 'src/sdk/session'

import { ProductShippingOptions } from '../ProductShippingOptions/ProductShippingOptions'
import Section from '../Section'
import { CollectionFlags } from './CollectionFlags'
import { MessageIndisponible, MessageStock } from './MessageStock'
import { ProductDetailsContent } from './ProductDetailsContent'
import { ProductPrice } from './ProductPrice'
import styles from './product-details.module.scss'

interface Props {
  nutritionalData: ProductNutritionalInfo
  product: ProductDetailsFragment_ProductFragment
  locationHref: string
}

function ProductDetails({
  product: staleProduct,
  locationHref,
  nutritionalData,
}: Props) {
  const INITIAL_QUANTITY = 1
  const allCmsFlags = useCmsCollectionFlags()

  const { currency } = useSession()
  const [quantityItems, setQuantityItems] = useState<number>(INITIAL_QUANTITY)
  const { items } = useCart()
  const { removeItem, updateItemQuantity } = cartStore
  const { isMobile } = useWindowDimensions()
  const { data, isValidating } = useProduct(staleProduct.id, {
    product: staleProduct,
  })

  if (!data) {
    throw new Error('NotFound')
  }

  const {
    product: {
      id,
      sku,
      gtin,
      sellers,
      description,
      clusterHighlights,
      name: variantName,
      brand,
      isVariantOf,
      isVariantOf: { name, skuVariants, hasVariant },
      image: productImages,
      offers: {
        offers: [
          {
            availability,
            price,
            listPrice,
            seller,
            seller: { identifier },
          },
        ],
      },
      unitMultiplier,
      measurementUnit,
      breadcrumbList: breadcrumbs,
      additionalProperty,
    },
  } = data

  const discountPercentage = useDiscountPercent(listPrice, price)

  const buyDisabled = availability !== 'https://schema.org/InStock'

  const buyProps = useBuyButton({
    id,
    price,
    listPrice,
    seller,
    quantity: quantityItems,
    unitMultiplier,
    measurementUnit,
    itemOffered: {
      sku,
      name: variantName,
      gtin,
      image: productImages,
      brand,
      isVariantOf,
      additionalProperty,
    },
  })

  const cartProduct = items?.find((item) => item?.itemOffered?.sku === sku)
  const sellerIdentifier = data.product.offers?.offers[0]?.seller.identifier
  const sellerDefault = '1'
  const sellerNameToDisplay = sellerIdentifier
    ? (
        sellers?.find(
          (sellerInfo) => sellerInfo?.sellerId === sellerIdentifier
        ) || {}
      ).sellerName
    : ''

  const filteredResults = hasVariant
    .filter((item) => item.additionalProperty.length > 0)
    .map((item) => {
      const stock =
        item.offers.offers[0].availability === 'https://schema.org/InStock'

      return {
        ...item,
        stock,
      }
    })

  const {
    delivery,
    pickupoint,
    loading,
    isShippingSameDayAndFree,
    isShippingSameDay,
  } = useShippingSimulation(sku, identifier)

  const sellerInfos = useGetSellerInfos(identifier)

  const {
    loading: multicurrencyLoading,
    multicurrecySellingPrice,
    multicurrencyListPrice,
  } = useGetMulticurrencyPrices(identifier, sku)

  useEffect(() => {
    sendAnalyticsEvent<ViewItemEvent<AnalyticsItem>>({
      name: 'view_item',
      params: {
        currency: currency.code as CurrencyCode,
        value: price,
        items: [
          {
            item_id: isVariantOf.productGroupID,
            item_name: isVariantOf.name,
            item_brand: brand.name,
            item_variant: sku,
            price,
            discount: listPrice - price,
            currency: currency.code as CurrencyCode,
            item_variant_name: variantName,
            product_reference_id: gtin,
          },
        ],
      },
    })
  }, [
    isVariantOf.productGroupID,
    isVariantOf.name,
    brand.name,
    sku,
    price,
    listPrice,
    currency.code,
    variantName,
    gtin,
  ])

  useEffect(() => {
    if (!cartProduct) {
      setQuantityItems(INITIAL_QUANTITY)
    }
  }, [cartProduct])

  return (
    <Section
      className={`${styles.fsProductListing} layout__content layout__section`}
      data-fs-product-details
    >
      <div data-fs-product-details__breadcrumb>
        <Breadcrumb breadcrumbList={breadcrumbs.itemListElement} />
      </div>
      <section data-fs-product-details__body>
        <ImageGallery images={productImages} locationHref={locationHref} />
        <CollectionFlags
          clusterHighlights={clusterHighlights}
          allCmsFlags={allCmsFlags}
        />
        <ShippingBadge
          isShippingSameDayAndFree={isShippingSameDayAndFree}
          isShippingSameDay={isShippingSameDay}
        />
        <header data-fs-product-details__title>
          <h2>{brand?.name}</h2>
          <ProductTitle title={<h1>{name}</h1>} />
          {sellerIdentifier !== sellerDefault && (
            <div data-fs-product-seller__name>
              <span>Vendido por {sellerNameToDisplay}</span>
              <ShopIcon />
            </div>
          )}
          <section data-fs-product-details__settings>
            <PriceSkeleton
              loading={isValidating || multicurrencyLoading}
              bordered
            >
              <ProductPrice
                data={data}
                discountPercentage={discountPercentage}
                multicurrecySellingPrice={multicurrecySellingPrice}
                multicurrencyListPrice={multicurrencyListPrice}
              />
            </PriceSkeleton>
            {filteredResults && skuVariants && (
              <CustomSelectors
                slugsMap={skuVariants.slugsMap}
                availableVariations={skuVariants.availableVariations}
                activeVariations={skuVariants.activeVariations}
                results={filteredResults}
                data-fs-product-details-selectors
              />
            )}

            {buyDisabled && <MessageIndisponible />}
            {/* NOTE: animation violation due to the button transitioning commit https://git.io/JyXV5. */}
            {isValidating ? (
              <AddToCartLoadingSkeleton />
            ) : (
              <>
                {availability && (
                  <>
                    {cartProduct?.id ? (
                      <>
                        <div data-fs-product-details__cantidad>
                          <span>Cantidad:</span>
                        </div>
                        <div data-fs-product-details__buy-options>
                          <div data-fs-buy-options__quantity-wrapper>
                            <QuantitySelector
                              cartQuantity={cartProduct?.quantity}
                              quantity={quantityItems}
                              setQuantity={setQuantityItems}
                              productId={cartProduct?.id}
                              removeItem={removeItem}
                              updateItems={updateItemQuantity}
                              className="buy-options__quantity-selector"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div data-fs-product-details__buy-options>
                        <div data-fs-buy-options__button-wrapper>
                          <BuyButton
                            data-fs-buy-options__buy-button
                            disabled={buyDisabled}
                            {...buyProps}
                          >
                            AGREGAR
                          </BuyButton>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isMobile && <MessageStock />}
              </>
            )}
            {!availability && (
              <OutOfStock
                onSubmit={(email) => {
                  console.info(email)
                }}
              />
            )}

            {identifier !== '1' && (
              <ProductShippingOptions
                delivery={delivery}
                pickupoint={pickupoint}
                loading={loading}
              />
            )}
          </section>
        </header>
      </section>
      {!isMobile && <MessageStock />}
      <ProductDetailsContent
        nutritionalData={nutritionalData}
        description={description}
        sellerInfos={sellerInfos}
      />
    </Section>
  )
}

function AddToCartLoadingSkeleton() {
  // Generated via https://skeletonreact.com/.
  return (
    <svg
      role="img"
      width="100%"
      height="48"
      aria-labelledby="loading-aria"
      viewBox="0 0 112 48"
      preserveAspectRatio="none"
    >
      <title id="loading-aria">Loading...</title>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath="url(#clip-path)"
        style={{ fill: 'url("#fill")' }}
      />
      <defs>
        <clipPath id="clip-path">
          <rect x="0" y="0" rx="2" ry="2" width="112" height="48" />
        </clipPath>
        <linearGradient id="fill">
          <stop offset="0.599964" stopColor="#f3f3f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1.59996" stopColor="#ecebeb" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="2.59996" stopColor="#f3f3f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const fragment = graphql`
  fragment ProductDetailsFragment_product on StoreProduct {
    id: productID
    slug
    sku
    sellers {
      sellerName
      sellerId
    }
    name
    gtin
    description
    clusterHighlights {
      id
      name
    }
    categoryId
    categoriesIds
    unitMultiplier
    measurementUnit
    ean
    isVariantOf {
      productGroupID
      name
      hasVariant {
        sku
        slug
        additionalProperty {
          name
          value
        }
        offers {
          offers {
            availability
            quantity
          }
        }
      }
      skuVariants {
        activeVariations
        slugsMap(dominantVariantName: "Talle")
        availableVariations(dominantVariantName: "Talle")
      }
    }
    image {
      url
      alternateName
    }
    brand {
      name
    }
    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        seller {
          identifier
        }
      }
    }
    breadcrumbList {
      itemListElement {
        item
        name
        position
      }
    }
    additionalProperty {
      propertyID
      name
      value
      valueReference
    }
  }
`

export default ProductDetails
