type ProductDescriptionProps = {
  description?: string
}

export const ProductDescription = ({
  description,
}: ProductDescriptionProps) => {
  return (
    <div data-fs-product-details-content>
      <p>{description}</p>
    </div>
  )
}
